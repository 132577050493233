import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Terms = () => {
  return (
    <div className="flex flex-col min-h-screen text-white">
      <Header />

      <div className="flex justify-center">
        <div className="w-full max-w-2xl p-4">

          <h2 className="text-2xl font-bold mb-4 text-center">Our Rules and Policies</h2>
          <ul className="list-none pl-6 py-5 rounded-2xl darker-discord-background">
            <li><strong>Follow Discord's Terms of Service</strong> - <a href="https://discord.com/terms" className="hover:underline text-gray-400">https://discord.com/terms</a></li>
            <li><strong>We are not responsible if you get scammed</strong>, but please make a report <a href="https://discord.gg/scammer-list-1021087112830914622" className="hover:underline text-gray-400">here</a></li><br/>
            <li><strong>By joining this server, you agree to these rules</strong>. It is your responsibility to check these rules and see if they have changed</li><br/>
            <li><strong>Staff reserves the right to warn, mute, or ban for offenses/situations not explicitly listed</strong>. Use your common sense.</li><br/>
            <li><strong>Joining this server is agreeing and confirming you have acknowledged the rules</strong>. Staff reserves the right to remove you from the server at any time for any reason. Failure to read the rules does not excuse you from them.</li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
