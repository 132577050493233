import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
const { serverInvite, bot } = require("../editable.json");

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    fetch(`https://listing.noemt.dev/bot/${bot}/accounts`)
      .then((response) => response.json())
      .then((accounts) => {
        setAccounts(accounts);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const footerText = "Made by notnomv6 | <a href='https://bots.noemt.dev' class='rainbow-text'>https://bots.noemt.dev</a>";

  const rank_images = {
    "Non": "/assets/NON.webp",
    "[VIP]": "/assets/VIP.webp",
    "[VIP+]": "/assets/VIP+.webp",
    "[MVP]": "/assets/MVP.webp",
    "[MVP+]": "/assets/MVP+.webp",
    "[MVP++]": "/assets/MVP++.webp",
  };

  return (
    <div className="flex flex-col min-h-screen text-white">
      <Header />

      <div>
        <h2 className="text-3xl font-bold text-center mb-8 mt-12">
          Our Accounts
        </h2>

        {isLoading ? (
          <p className="text-center">Loading...</p>
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 justify-items-center">
            {accounts.map((account) => (
              <div
                key={account.id}
                className="discord-embed rounded-lg shadow-lg p-4 m-4 w-11/12 md:w-3/5 lg:w-4/5 relative"
              >
                <h2 className="text-xl bolded mb-4">Account Information</h2>

                <div className="items-left mb-2">
                  <p className="text-sm bolded">Rank</p>
                  <img
                    src={rank_images[account.rank] || "/assets/DUNNO.webp"}
                    className="h-5"
                    alt="Rank"
                  />
                </div>
                <img
                  src="/assets/anon.webp"
                  alt="Profile"
                  className="mr-2 absolute top-2 right-2 w-11"
                />
                <a href={serverInvite}>
                  <button className="discord-button absolute bottom-2 right-2 rounded-lg flex items-center justify-center w-1/6 h-9">
                    <img
                      src="/assets/money-sign.svg"
                      alt="Buy Icon"
                      style={{ marginRight: "8px" }}
                      className="h-2/3"
                    />
                    Buy
                  </button>
                </a>
                <div className="flex justify-between mb-2">
                  <div>
                    <div className="flex items-center">
                      <img
                        src="/assets/SKILLS.webp"
                        alt="Icon"
                        className="w-4 h-4 mr-1"
                      />
                      <p className="bolded text-sm">Skill Average</p>
                    </div>
                    <p className="text-sm">{account.skill_average}</p>
                  </div>
                  <div className="flex-grow text-center">
                    <div className="flex items-center justify-center">
                      <img
                        src="/assets/MORT.webp"
                        alt="Catacombs Icon"
                        className="w-4 h-4 mr-1"
                      />
                      <p className="bolded text-sm">Catacombs</p>
                    </div>
                    <p className="text-sm -ml-0.5">
                      {account.catacombs_level} ({account.catacombs_xp} XP)
                    </p>
                  </div>
                  <div></div>
                </div>

                <div className="flex justify-between mb-2">
                  <div>
                    <div className="flex items-center">
                      <img
                        src="/assets/REVENANT_HORROR.webp"
                        alt="Slayers Icon"
                        className="w-4 h-4 mr-1"
                      />
                      <p className="bolded text-sm">Slayers</p>
                    </div>
                    <p className="text-sm">{account.slayers}</p>
                  </div>
                  <div className="flex-grow text-center">
                    <div className="flex justify-center items-center">
                      <img
                        src="/assets/SKYBLOCK_LEVEL.webp"
                        alt="Level Icon"
                        className="w-4 h-4 mr-1"
                      />
                      <p className="bolded text-sm">Level</p>
                    </div>
                    <p className="text-sm -ml-3">{account.level}</p>
                  </div>
                  <div></div>
                </div>

                <div className="mb-2">
                  <div className="flex items-center">
                    <img
                      src="/assets/BANK_ITEM.webp"
                      alt="Networth Icon"
                      className="w-4 h-4 mr-1"
                    />
                    <p className="text-sm bolded">Networth</p>
                  </div>
                  <p className="text-sm">
                    {account.networth} ({account.coins} Coins)
                  </p>
                  <p className="text-sm">{account.soulbound} Soulbound</p>
                </div>

                <div className="mb-2">
                  <div className="flex items-center">
                    <img
                      src="/assets/HOTM_TREE.webp"
                      alt="HOTM Icon"
                      className="w-4 h-4 mr-1"
                    />
                    <p className="text-sm bolded">HOTM</p>
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/STONE_PICKAXE.webp"
                      alt="Heart Icon"
                      className="w-4 h-4 mr-1"
                    />
                    <p className="text-sm">
                      Heart of the Mountain: {account.hotm}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/MITHRIL_POWDER.webp"
                      alt="Mithril Powder Icon"
                      className="w-4 h-4 mr-1"
                    />
                    <p className="text-sm">
                      Mithril Powder: {account.mithril_powder}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/GEMSTONE_POWDER.webp"
                      alt="Gemstone Powder Icon"
                      className="w-4 h-4 mr-1"
                    />
                    <p className="text-sm">
                      Gemstone Powder: {account.gemstone_powder}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <img
                      src="/assets/POWDER_GLACITE.webp"
                      alt="Glacite Powder Icon"
                      className="w-4 h-4 mr-1"
                    />
                    <p className="text-sm">
                      Glacite Powder: {account.glacite_powder}
                    </p>
                  </div>
                </div>

                <div className="flex justify-between mb-2">
                  <div>
                    <div className="flex items-center">
                      <img
                        src="/assets/money-sign.svg"
                        alt="Icon"
                        className="w-4 h-4 mr-1"
                      />
                      <p className="bolded text-sm">Price</p>
                    </div>
                    <p className="text-sm">{account.price}$</p>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <img
                        src="/assets/card.svg"
                        alt="Icon"
                        className="w-4 h-4 mr-1"
                      />
                      <p className="bolded text-sm">Payment Method(s)</p>
                    </div>
                    <div className="flex">
                      {account.payment.map((method) => (
                        <>
                          <img
                            key={method}
                            src={`/assets/${method}.webp`}
                            alt={method}
                            className="w-4 h-4 mr-2"
                          />
                          {method ===
                          account.payment[account.payment.length - 1] ? null : (
                            <span className="text-sm -mt-0.5 mr-1">/</span>
                            )}
                            </>
                        ))}
                        </div>
                    </div>
                    </div>

                    <p className="text-xs text-gray-500 mt-4 flex items-center">
                    <img src="/assets/footer-logo.webp" alt="Icon" className="w-5 h-5 mr-2" />
                    <div dangerouslySetInnerHTML={{ __html: footerText }} />                
                    </p>
                </div>

              ))}
            </div>
          )}
      </div>

      <Footer />
    </div>
  );
};

export default Accounts;