import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen text-white">
      <Header />

      <div className="flex justify-center">
        <div className="w-full max-w-2xl p-4">

          <h2 className="text-2xl font-bold mb-4 text-center">Privacy Policy</h2>
          <div className="list-none pl-6 py-5 rounded-2xl darker-discord-background px-5">
            <p><strong>Effective Date:</strong> 27-Jul 2024</p><br/>

            <h3 className="text-xl font-bold mb-2">1. Introduction</h3>
            <p>Welcome to Pixly ("we," "our," or "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you open a ticket to report a user through our platform.</p><br/>

            <h3 className="text-xl font-bold mb-2">2. Information We Collect</h3>
            <p>When you open a ticket to report a user, we may collect the following types of information:</p>
            <ul className="list-disc list-inside">
              <li><strong>Message Content:</strong> Any messages, descriptions, or other content you provide in the ticket.</li>
            </ul><br/>

            <h3 className="text-xl font-bold mb-2">3. How We Use Your Information</h3>
            <p>The information we collect when you open a ticket to report a user may be used for the following purposes:</p>
            <ul className="list-disc list-inside">
              <li><strong>Investigation and Resolution:</strong> To investigate and address the issues reported, ensuring a safe and respectful environment for all users.</li>
              <li><strong>Record Keeping:</strong> To maintain records of reports and actions taken for operational purposes.</li>
            </ul><br/>

            <h3 className="text-xl font-bold mb-2">4. Logging and Monitoring</h3>
            <p>Please be aware that when you open a ticket to report a user, your messages and any other information you provide may be logged and monitored. This is done to generate transcripts of reports and to ensure the integrity of our platform. These logs are used only to address further discrepancies that may arise and for maintaining transparency.</p><br/>

            <h3 className="text-xl font-bold mb-2">5. Public Availability for Transparency</h3>
            <p>For transparency reasons, the content of report tickets is shared with anyone who is in the server. This means that any information you provide in your report, including the message content, will be accessible to other server members. We believe that this transparency is crucial for maintaining a trustworthy and open platform.</p><br/>

            <h3 className="text-xl font-bold mb-2">6. Data Security</h3>
            <p>We take the security of your personal information seriously and implement appropriate measures to protect it from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or method of electronic storage is 100% secure.</p><br/>

            <h3 className="text-xl font-bold mb-2">7. Your Rights</h3>
            <p>You have the right to access, update, or delete your personal information. If you wish to exercise any of these rights, please contact us at [Your Contact Information].</p><br/>

            <h3 className="text-xl font-bold mb-2">8. Changes to This Privacy Policy</h3>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our platform. You are advised to review this Privacy Policy periodically for any changes.</p><br/>

            <h3 className="text-xl font-bold mb-2">9. Contact Us</h3>
            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
            <p><br/>
            notnomv6 on discord<br/>
            pixly@noemt.dev</p><br/>

            <p>By using our platform and submitting a ticket to report a user, you acknowledge that you have read, understood, and agreed to the terms of this Privacy Policy.</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
