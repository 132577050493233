import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
const { bot } = require("../editable.json")

const Vouches = () => {
  const [vouches, setVouches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    fetch(`https://listing.noemt.dev/bot/${bot}/vouches`)
      .then((response) => response.json())
      .then((vouches) => {

        let processed = []

        vouches.forEach(vouch => {
            if (vouch.avatar == null) vouch.avatar="https://cdn.discordapp.com/embed/avatars/5.png"
            processed.push(vouch)
        })

        setVouches(processed); 
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="flex flex-col min-h-screen text-white">
      <Header />

      <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <section>
          <h2 className="text-3xl font-bold text-center mb-8">
            Vouches
          </h2>

          {isLoading ? (
            <p className="text-center">Loading...</p>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
              {vouches.map((vouch) => (
                <div
                  key={vouch.id}
                  className="darker-discord-background rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300 relative"
                >
                    <div className="flex items-center">
                    <img
                        src={vouch.avatar}
                        alt={`${vouch.username} avatar`}
                        className="w-10 h-10 rounded-full mb-4 object-cover border-solid border-white border-2"
                    />
                    <h3 className="text-xl font-semibold text-left ml-2 mb-4">
                        <a className="hover:underline" href={`https://discord.com/users/${vouch.id}`}>
                        {vouch.username}
                        </a>
                    </h3>
                    </div>
                  <p>{vouch.message}</p>
                </div>
              ))}
            </div>
          )}
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Vouches;